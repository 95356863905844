/* global $ */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleElem", "toggleTarget"];

  connect() {
    [...this.toggleTargetTargets.map((input) => $(input).slideUp())];
  }

  toggle(e) {
    $(e.currentTarget).next(".address").slideToggle("slow");
  }
}
